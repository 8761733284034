import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Layout from '../../../components/layout/layout';

import { Item } from '@features/mypage/History/Item';

const ItemPage: FC<PageProps> = ({ params }) => {
  const id = parseInt(params.id);
  return (
    <Layout>
      <Item id={id} />
    </Layout>
  );
};

export default ItemPage;
